html, body {
    overflow: hidden;

    height: 100vh;
    width: 100vw;

    margin: 0;
}

#root {
    height: 100vh;
    width: 100vw;

    overflow: hidden;
}


*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
    background: rgba(85, 108, 214, 0.5);
    border-radius: 2px
}
